


















import { defineComponent, useContext, useRouter } from '@nuxtjs/composition-api';
import { SfTabs } from '@storefront-ui/vue';
import { useAddresses } from '~/modules/customer/composables/useAddresses';
import { CustomerAddress } from '~/modules/GraphQL/types';
import { SubmitEventPayload } from '~/modules/customer/types/form';
import AddressForm from '~/modules/customer/pages/MyAccount/AddressesDetails/AddressForm.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';

export default defineComponent({
  name: 'AddressNew',
  components: { SfTabs, AddressForm },
  setup() {
    const context = useContext();
    const router = useRouter();
    const useAddressesComposable = useAddresses();

    const createAddress = async ({ form } : SubmitEventPayload<CustomerAddress>) => {
      await useAddressesComposable.save({ address: form });
      await router.push(context.localeRoute({ name: 'customer-addresses-details' }));
    };

    const pageMeta = {
      "meta_title": null,
      "meta_description": null,
      "meta_keywords": null,
      "name": "Midwest Photo Address Book",
    };

    return { createAddress, pageMeta };
  },
  head() {
    return getMetaInfo(this.pageMeta);
  },
});
